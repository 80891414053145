import React from 'react';
import { PanelProps } from '@grafana/data';
import { UptimeOptions } from 'types';
import ChartComponent from 'react-chartjs-2';
import 'chartjs-chart-timeline';
import { first, last, capitalize } from 'lodash';
import { intervalToDuration, formatDuration, format } from 'date-fns';

import './styles.scss';

interface Props extends PanelProps<UptimeOptions> {}

interface Dataset {
  [index: string]: any[];
}

interface ChartOptions {
  [index: string]: any;
}

export const UptimePanel: React.FC<Props> = ({ options, data, width, height, fieldConfig }) => {
  const chartReference = React.createRef<ChartComponent<any>>();

  React.useEffect(() => {
    let chart = chartReference.current;
    if (chart === null) {
      return;
    }
    let canvas = chart.chartInstance.canvas;
    if (canvas === null) {
      return;
    }
    canvas.style.maxWidth = `${width}px`;
    canvas.style.maxHeight = `${height}px`;
  });

  let generateData = () => {
    let fields = data.series[0].fields;
    let datasets: Dataset = {};

    // Generate datasets
    for (let i = 0; i < fields[0].values.length; i++) {
      let monitor: string = fields[0].values.get(i);
      if (!datasets.hasOwnProperty(monitor)) {
        datasets[monitor] = [];
      }
      let startTimestamp = fields[1].values.get(i);
      let endTimestamp = fields[2].values.get(i);
      let status = fields[3].values.get(i);
      datasets[monitor].push([startTimestamp, endTimestamp, status]);
    }

    // Clamp start and end to grafana specified timestamps
    Object.keys(datasets).forEach((key) => {
      let firstItem = first(datasets[key]);
      let firstItemStart = new Date(firstItem[0]);
      let lastItem = last(datasets[key]);
      let lastItemEnd = new Date(lastItem[1]);
      let grafanaFrom = data.timeRange.from.toDate();
      let grafanaTo = data.timeRange.to.toDate();
      if (firstItemStart < grafanaFrom) {
        firstItem.push(firstItem[0]);
        firstItem.push(null);
        firstItem[0] = grafanaFrom.toISOString();
      }
      if (lastItemEnd > grafanaTo) {
        lastItem.push(null);
        lastItem.push(lastItem[1]);
        lastItem[1] = grafanaTo.toISOString();
      }
    });

    // return data in ChartJS format
    return {
      labels: Object.keys(datasets),
      datasets: Object.keys(datasets).map((monitor) => {
        return { label: monitor, type: 'timeline', data: datasets[monitor] };
      }),
    };
  };

  let chartOptions: ChartOptions = {
    elements: {
      colorFunction: (_text: string, data: string[], _dataset: any, _index: number) => {
        if (data[2] === 'up') {
          return options.upColour;
        } else {
          return options.downColour;
        }
      },
      showText: false,
    },
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          let dataset = data.datasets[tooltipItem.datasetIndex];
          let index = tooltipItem.index;
          let rawData = dataset.data[index];
          let start = new Date(rawData[3] ? rawData[3] : rawData[0]);
          let end = new Date(rawData[4] ? rawData[4] : rawData[1]);
          return [
            `Start: ${format(start, options.tooltipTimestampFormat)}`,
            `End: ${format(end, options.tooltipTimestampFormat)}`,
            `Duration: ${formatDuration(intervalToDuration({ start: start, end: end }))}`,
            `Status: ${capitalize(rawData[2])}`,
          ];
        },
      },
    },
  };

  return (
    <div className="rradar-uptime-panel" style={{ width: width, height: height, position: 'relative' }}>
      {data.series[0].fields.length !== 4 ? (
        <p>Query must return 4 columns in the following order... Name, Start Timestamp, End Timestamp, Status</p>
      ) : (
        <>
          {options.showLegend && (
            <div className="legend">
              <div style={{ background: options.upColour }}></div>
              <span>Up</span>
              <div style={{ background: options.downColour }}></div>
              <span>Down</span>
              {options.legendText && (
                <>
                  <span>|</span>
                  <span>{options.legendText}</span>
                </>
              )}
            </div>
          )}
          <div className="chart" style={{}}>
            <ChartComponent type="timeline" ref={chartReference} options={chartOptions} data={generateData()} />
          </div>
        </>
      )}
    </div>
  );
};
